<template>
    <div class="px-3 py-2">
        <b-modal
            id="emp_custody"
            ref="emp_custody"
            :title="$parent.lang.emp_custody"
            size="lg"
            :footer-bg-variant="`warning`"
            :header-bg-variant="`dark`"
            :header-text-variant="`light`"
            lazy
        >
            <template #modal-header >
                <div class="w-100 flexit" style="direction:rtl;text-align:center">
                    <div style="margin-bottom:5px!important;font-size:1.3rem;border:2px solid #fff !important;width:150px;display:flex;justify-content: center;justify-items: center;align-items: center;">
                        {{ $parent.lang.emp_custody }}
                    </div>
                </div>
            </template>

                <div class="px-3 py-2" :style="`direction:`+$parent.lang.dir">
                    <v-form ref="apform">
                        <v-row>
                            <v-col cols="12" md="6" sm="12">
                                <label>{{$parent.lang.emp_name}}</label>
                                <b-form-select class="selborder"
                                    :options="employees"
                                    v-model="empid"
                                    ></b-form-select>
                            </v-col>
                            <v-col cols="12" md="3" sm="12">
                                <label>{{$parent.lang.custody_amount}}</label>
                                <b-form-input class="inborder"
                                    :label="$parent.lang.custody_amount"
                                    v-model="amount"
                                    :rules="fieldRules"
                                    >{{ amount }}</b-form-input>
                            </v-col>
                            <v-col cols="12" md="3" sm="12">
                                <label>{{$parent.lang.payment_type}}</label>
                                <b-input-group>
                                <b-form-select
                                    class="selborder"
                                    v-model="payment_type"
                                    :options="paytypes"
                                    :label="$parent.lang.payment_type"
                                    ></b-form-select>
                                <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                                    <i class="fas fa-arrow-down"></i>
                                    </b-input-group-append>
                                </b-input-group>
                            </v-col>
                            
                        </v-row>
                    </v-form>
                </div>

                <template #modal-footer="{ close }" >
                <div class="w-100" style="direction:rtl;text-align:center;">
                    <b-button type="button" @click='addit()' style="font-size:1.1rem;border:2px solid #fff !important;background:green !important;width:120px;color:#fff;" class="mytab2 btn btn-sm btn-success ms-2">{{ $parent.lang.add }}</b-button>
                    <b-button type="button" @click='close()' style="font-size:1.1rem;border:2px solid #fff !important;background:red !important;width:120px;color:#fff;" class="mytab2 btn btn-sm btn-success ms-2">{{ $parent.lang.close }}</b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify';
export default{
    data() {
        return {
            payment_type: 1,
            empid: 0,
            employees:[],
            amount: '',
            paytypes: [],
            fieldRules: [
                v => !!v || this.$parent.lang.required_field
            ]
        }
    },
    created(){
        this.getEmp();
        this.getPyamentType();
    },
    methods: {
        getEmp(){
            const post = new FormData();
            post.append('type','getEmployees');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',"all");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                for(let i=0;i<res.results.data1.length;i++){
                    this.employees.push({
                        text: res.results.data1[i].employee_name,
                        value: res.results.data1[i].id,
                    })
                }
            })
        },
        getPyamentType(){
            const post = new FormData();
            post.append('type','getPayTypes');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',"all");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.payTypeOp = [];
                for(let i=0;i<res.results.data.length;i++){
                    this.paytypes.push({
                        text: this.$parent.lang.langname == 'ar' ? res.results.data[i].type_name : res.results.data[i].type_name_en,
                        value: res.results.data[i].id
                    })
                }
            })
        },
        addit() {
            let vald = true;
            let message = '';
            if(this.amount == '' || this.amount <= 0){
                vald = false;
                message = this.$parent.lang.mor_than_zero;
            }
            if(!vald){
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            const post = new FormData();
            post.append("type","addCustody");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase))
            post.append("data[amount]",this.amount);
            post.append("data[empid]",this.empid);
            post.append("data[paytype]",this.payment_type);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                // console.log(response.data);
                this.amount = '',  
                this.$parent.getEmployees();             
                document.getElementById('cdedhideExp').click();
            })
        }
    },
}
</script>